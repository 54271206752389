<script>
import {
  Carousel,
  Slide
} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
// import Footer from "@/components/footer";
import Navbar from "@/views/new-view/components/navbar";
import Footer from "@/views/new-view/components/footerBar";
import referralApi from "@/apis/referralApi";
import storage from '../../storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";
import date from "../../date";
// import Navbar2 from "../../components/navbar2.vue";
import blogApi from '../../apis/blogApi';
// import Navbar from "../../components/navbar.vue";
// import Navbar3 from "../../components/navbar3.vue";

/**
 * Index-1
 */
export default {
  metaInfo: {
    title: 'IncCrypto: The Best Cloud Mining Platform and Service Provider for Cloud Mining Technology Services',
    link: [
      { rel: 'canonical', href: 'https://inccrypto.com/what-is-bitcoin' }
    ]
  },
  data() {
    return {
      data: [],
      login: false
    }
  },
  components: {
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    // Navbar2,
    Navbar,
    // Navbar3
  },
  created() {
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true;
    } else {

    }
  }
};
</script>

<template>
  <div>
    <Navbar v-if="!login"/>
    <Navbar v-else/>


    <!-- 顶部 -->
    <section class="bg-half-170 d-table w-100 pb-0" style="padding-top: 100px;">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">What Is Bitcoin</h4>
            <!--            <p class="text-muted mx-auto mb-0">-->
            <!--              If you could not find an answer to your questions,please contant our customer support-->
            <!--            </p>-->
          </div>
        </div>
      </div>
    </section>
    <section class="section pt-0" style="margin: 0 10px;">
      <div class="container" style="border-radius: 10px;">
        <h3>What is Bitcoin?</h3>
        <br/>
        <p>Bitcoin(BTC) is an innovative payment network and a new kind of money.</p>
        <p>Bitcoin uses peer-to-peer technology to operate with no central authority or banks; managing transactions and the issuing of Bitcoins is carried out collectively by the network. Bitcoin is open-source; its design is public, nobody owns or controls Bitcoin and everyone can take part. Through many of its unique properties, Bitcoin allows exciting uses that could not be covered by any previous payment system.</p>
        <strong style="font-size:22px;">
          Who created it?
        </strong><br/><br>

        <p>A pseudonymous software developer going by the name of Satoshi Nakamoto proposed bitcoin in 2008, as an electronic payment system based on mathematical proof. The idea was to produce a means of exchange, independent of any central authority, that could be transferred electronically in a secure, verifiable and immutable way.</p>
        <p>To this day, no-one knows who Satoshi Nakamoto really is.</p>
        <p>In what ways is it different from traditional currencies?</p>
        <p>Bitcoin can be used to pay for things electronically, if both parties are willing. In that sense, it's like conventional dollars, euros, or yen, which are also traded digitally.</p>
        <p>But it differs from fiat digital currencies in several important ways:</p>

        <strong style="font-size:22px;">
          1 – Decentralization
        </strong><br/><br>
        <p>Bitcoin's most important characteristic is that it is decentralized. No single institution controls the bitcoin network. It is maintained by a group of volunteer coders, and run by an open network of dedicated computers spread around the world. This attracts individuals and groups that are uncomfortable with the control that banks or government institutions have over their money.</p>
        <p>Bitcoin solves the "double spending problem" of electronic currencies (in which digital assets can easily be copied and re-used) through an ingenious combination of cryptography and economic incentives. In electronic fiat currencies, this function is fulfilled by banks, which gives them control over the traditional system. With bitcoin, the integrity of the transactions is maintained by a distributed and open network, owned by no-one.</p>

        <strong style="font-size:22px;">
          2 - Limited supply
        </strong><br/>
        <p>Fiat currencies (dollars, euros, yen, etc.) have an unlimited supply – central banks can issue as many as they want, and can attempt to manipulate a currency's value relative to others. Holders of the currency (and especially citizens with little alternative) bear the cost.</p>
        <p>With bitcoin, on the other hand, the supply is tightly controlled by the underlying algorithm. A small number of new bitcoins trickle out every hour, and will continue to do so at a diminishing rate until a maximum of 21 million has been reached. This makes bitcoin more attractive as an asset – in theory, if demand grows and the supply remains the same, the value will increase.</p>

        <strong style="font-size:22px;">
          3 - Pseudonymity
        </strong>
        <p>The information, provided to us can be used for:verification and authentication of the identity of users, that open and manage accounts in order to prevent fraud, deception and other illegal actions.compliance with legal requirements regarding the legalization (laundering) of proceeds from crime, the financing of terrorism.informing you about changes related to our services.administering site and conducting internal operations, including troubleshooting, data analysis, testing, collecting statistical data and for conducting surveys.ensure the security of our site, our services, as well as your accounts.providing support, prompt response and resolution for your complaints, questions related to the use of our services and the capabilities of our website.It is important that your personal information that we adhere to is accurate and up to date.In the occasion of any changes to your personal information, you must immediately correct your data or inform us for correction.</p>

        <strong style="font-size:22px;">
          3. USE OF COOKIES
        </strong>
        <p>While senders of traditional electronic payments are usually identified (for verification purposes, and to comply with anti-money laundering and other legislation), users of bitcoin in theory operate in semi-anonymity. Since there is no central "validator," users do not need to identify themselves when sending bitcoin to another user. When a transaction request is submitted, the protocol checks all previous transactions to confirm that the sender has the necessary bitcoin as well as the authority to send them. The system does not need to know his or her identity.</p>
        <p>In practice, each user is identified by the address of his or her wallet. Transactions can, with some effort, be tracked this way. Also, law enforcement has developed methods to identify users if necessary.</p>
        <p>Furthermore, most exchanges are required by law to perform identity checks on their customers before they are allowed to buy or sell bitcoin, facilitating another way that bitcoin usage can be tracked. Since the network is transparent, the progress of a particular transaction is visible to all.</p>
        <p>This makes bitcoin not an ideal currency for criminals, terrorists or money-launderers.</p>

        <strong style="font-size:22px;">
          4 - Immutability
        </strong>
        <p>Bitcoin transactions cannot be reversed, unlike electronic fiat transactions.</p>
        <p>This is because there is no central "adjudicator" that can say "ok, return the money." If a transaction is recorded on the network, and if more than an hour has passed, it is impossible to modify.</p>
        <p>While this may disquiet some, it does mean that any transaction on the bitcoin network cannot be tampered with.</p>

        <strong style="font-size:22px;">
          5 - Divisibility
        </strong>
        <p>The smallest unit of a bitcoin is called a satoshi. It is one hundred millionth of a bitcoin (0.00000001) – at today's prices, about one hundredth of a cent. This could conceivably enable micro transactions that traditional electronic money cannot.</p>
        <p>Website: https://bitcoin.org/</p>

        <strong style="font-size:22px;">
          What is Ethereum?
        </strong>
        <p>Ethereum is a blockchain app platform.</p>
        <p>Ethereum is an open-source, public, blockchain-based distributed computing platform and operating system featuring smart contract (scripting) functionality. It supports a modified version of Nakamoto consensus via transaction-based state transitions.</p>
        <p>Ether(ETH) is a cryptocurrency whose blockchain is generated by the Ethereum platform. Ether can be transferred between accounts and used to compensate participant mining nodes for computations performed.  Ethereum provides a decentralized Turing-complete virtual machine, the Ethereum Virtual Machine (EVM), which can execute scripts using an international network of public nodes. "Gas", an internal transaction pricing mechanism, is used to mitigate spam and allocate resources on the network.</p>
        <p>Ethereum was proposed in late 2013 by Vitalik Buterin, a cryptocurrency researcher and programmer. Development was funded by an online crowdsale that took place between July and August 2014. The system went live on 30 July 2015, with 11.9 million coins "premined" for the crowdsale. This accounts for about 13 percent of the total circulating supply.</p>
        <p>In 2016, Ethereum was split into two separate blockchains – the new separate version became Ethereum (ETH), and the original continued as Ethereum Classic (ETC).The value of the Ethereum currency grew over 13,000 percent in 2017.</p>
        <p>Website: https://ethereum.org/ </p>



      </div>
    </section>



    <Footer/>
    <!-- Footer End -->
<!--    <Switcher/>-->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"
       v-scroll-to="'#topnav'">
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
